<template>
  <base-layout
    page-title=""
    showBackButton="true"
  >
    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col
        :size="getColSize(true)"
        :offset="getOffsetSize(true)"
        class="ion-no-padding ion-no-margin"
      >
        <ion-item v-if="!thePostIsLoaded" class="left-margin-15px ion-no-padding ion-no-margin">
          {{ getStrings(strings, "LoadingPost") }}...
        </ion-item>
        <post-list-item v-else :post="loadedpost" :isSinglePost="true"></post-list-item>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonItem,
  IonCol,
  IonRow
} from "@ionic/vue";

import { useRouter } from "vue-router";

import { homeOutline } from "ionicons/icons";

import { getColSize, getOffsetSize, processPreview } from "../services/utils";
import { getStrings } from "../services/lang";

import PostListItem from "../components/posts/PostListItem.vue";
import { fetchPost } from "../services/apiCall";

export default {
  components: {
    PostListItem,
    IonItem,
    IonCol,
    IonRow,
  },
  data() {
    const router = useRouter();

    return {
      router,
      postId: this.$route.params.postid,
      homeOutline,
      thePostIsLoaded: false,
      beingInited: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    loadedpost() {
      // console.log('this.postId:', this.postId);
      return this.$store.getters.post(this.postId);
    },
  },
  ionViewWillEnter() {
    this.postId = this.$route.params.postid;
    this.init();
  },
  watch: {
    "$route.params.postid": function(newPostID) {
      this.postId = newPostID;
      this.init();
    },
  },
  setup() {
    return {
      getColSize,
      getOffsetSize,
      processPreview,
      getStrings,
    };
  },
  methods: {
    init() {
      if (this.beingInited) return;

      this.beingInited = true;
      console.log('post-detail-init');

      this.thePostIsLoaded = false;
      
      if (
        this.postId === null ||
        typeof this.postId === "undefined" ||
        this.postId === ""
      ) {
        this.beingInited = false;
        return;
      }

      fetchPost(this.postId)
        .then(async (res) => {

          if (res.data.status === 0) {
            var post = res.data.result;
            var previewData = processPreview(post);
            post['previewData'] = previewData;

            this.$store.dispatch("addpost", post);

            this.thePostIsLoaded = true;
            this.beingInited = false;
          } else {
            console.log("post fetch err - 1", res.data.error);
            this.thePostIsLoaded = false;
            this.beingInited = false;
            this.router.replace("/posts");
          }
        })
        .catch(async (err) => {
          console.log("post fetch err - 2", err);
          this.thePostIsLoaded = false;
          this.beingInited = false;
          this.router.replace("/posts");
        });
    },
  },
};
</script>